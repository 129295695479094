<template>
  <div
    class="mt-3 text-sm"
    data-el="customer-consents"
  >
    <UiCheckbox
      v-if="props.allowSelectAll"
      id="select-all"
      name="select-all"
      :error-message-class="styles.checkbox.errorMessage"
      type="checkbox"
      :variant="props.checkboxVariant"
      :size="props.checkboxSize"
      :indeterminate="isSelectAllCheckboxIndeterminate"
      :label-visible="true"
      :class="styles.checkbox.el"
      :model-value="isSelectAllSelected"
      @change="toggleAllConsents"
    >
      <span class="mt-1.5">{{ t("select_all") }}</span>
    </UiCheckbox>

    <!-- Reusable toggle button for full description in consents -->
    <ConsentDescription.define v-slot="{ consent, required }">
      <component
        :is="consent?.moreDescription ? 'details' : 'div'"
        v-if="consent?.description"
        class="group relative mt-1 flex w-full select-none"
        @click="toggleFix"
      >
        <component
          :is="consent?.moreDescription ? 'summary' : 'div'"
          class="flex list-none flex-col items-start gap-1 md:flex-row md:items-center"
        >
          <!-- eslint-disable tailwindcss/no-custom-classname -->
          <HtmlParser
            v-if="consent?.description"
            :html="consent?.description"
            class="agreement-content"
            :class="{
              'agreement-content--required': typeof required != null && typeof required === 'boolean'
                ? required
                : isConsentRequired(consent),
              'agreement-content--email-only': props.marketingEmailOnly,
            }"
            data-el="consent-label"
          />
          <!-- eslint-enable tailwindcss/no-custom-classname -->

          <div
            v-if="consent?.moreDescription"
            class="relative flex cursor-pointer items-center gap-1 whitespace-nowrap text-right"
            data-test="customer-consent-expand-button"
          >
            <span class="block group-open:hidden">{{
              t("customer_full_consent_description")
            }}</span>
            <span class="hidden group-open:block">{{ t("collapse") }}</span>

            <UiIcon
              name="arrow"
              :width="9"
              :height="14"
              class="ml-1.5 mt-0.5 shrink-0 rotate-90 group-open:-rotate-90"
            />
          </div>
        </component>

        <!-- eslint-disable tailwindcss/no-custom-classname -->
        <HtmlParser
          v-if="consent?.moreDescription"
          :html="consent?.moreDescription"
          class="agreement-content ml-1 mt-3.5"
          data-test="customer-consent-full-content"
        />
        <!-- eslint-enable tailwindcss/no-custom-classname -->
      </component>
    </ConsentDescription.define>

    <fieldset
      v-for="(consent, index) of consents"
      :key="consent?.code || `consent-${props.area}-${index}`"
      v-bind="$attrs"
      class="relative mb-5 flex flex-col"
      data-el="customer-consent"
      :class="{
        'ml-11 mt-4': props.allowSelectAll,
      }"
    >
      <!-- content section available in edit mode -->
      <template v-if="props.editToggle">
        <div class="mb-4 flex">
          <button
            v-if="!isInEditMode || isConsentEditable(consent?.consentId)"
            class="ml-auto cursor-pointer whitespace-nowrap text-sm font-medium lowercase text-blue-150 underline"
            @click.prevent="
              switchEditMode(
                consent?.consentId,
                isConsentEditable(consent?.consentId),
              )
            "
          >
            {{
              isConsentEditable(consent?.consentId)
                ? t("cancel")
                : t("customer_edit_agreement")
            }}
          </button>
        </div>

        <template v-if="consent?.description && !isConsentEditable(consent?.consentId)">
          <details class="group">
            <summary class="list-none">
              <HtmlParser
                v-if="consent.description"
                tag="span"
                :html="consent.description"
                class="mr-2.5 inline-block"
              />
              <span
                v-if="consent.moreDescription"
                class="inline-block cursor-pointer whitespace-nowrap text-sm font-medium text-blue-150 underline"
              >
                <span class="group-open:hidden">
                  {{ t("customer_expand") }} >>
                </span>
                <span class="hidden group-open:inline-block">
                  {{ t("collapse") }} &lt;&lt;
                </span>
              </span>
            </summary>
            <HtmlParser
              v-if="consent.moreDescription"
              :html="consent.moreDescription"
              class="block pt-4 text-inherit [&>p]:text-sm"
            />
          </details>
          <div
            v-if="consent && consent?.children?.length"
            class="relative z-10 mt-4 flex flex-row flex-wrap gap-x-10 gap-y-5"
          >
            <template
              v-for="child of consent.children"
              :key="child.code"
            >
              <UiFormFieldCheckbox
                v-if="child && child.consentId"
                :id="generateUniqueId(`${child.consentId}-preview`)"
                :error-message-class="styles.checkbox.errorMessage"
                :name="`${child.consentId}-preview`"
                type="checkbox"
                :rules="child.isRequired ? 'required' : ''"
                container-tag="div"
                :class="styles.checkbox.el"
                :model-value="isConsentActive(child)"
                :variant="props.checkboxVariant"
                :size="props.checkboxSize"
                :label-visible="true"
                :disabled="true"
              >
                <div
                  v-if="child.description"
                  class="flex cursor-default flex-col pt-0.5"
                >
                  <HtmlParser
                    :html="child.description"
                    class="block leading-7"
                  />
                </div>
              </UiFormFieldCheckbox>
            </template>
          </div>
        </template>
      </template>
      <!-- /content section available in edit mode -->

      <div
        v-show="consent
          && consent.consentId
          && !(props.editToggle && !isConsentEditable(consent?.consentId))
        "
        :class="{
          'pointer-events-none cursor-not-allowed opacity-50':
            !isConsentEditable(consent?.consentId),
        }"
      >
        <!-- parent consent -->
        <UiFormFieldCheckbox
          v-if="consent"
          :id="generateUniqueId(consent.consentId as string)"
          :error-message-class="styles.checkbox.errorMessage"
          :name="consent.consentId as string"
          type="checkbox"
          :model-value="isConsentActive(consent)"
          :rules="isConsentRequired(consent) ? 'required' : ''"
          container-tag="div"
          :container-class="props.containerClass"
          :variant="props.checkboxVariant"
          :size="props.checkboxSize"
          :class="styles.checkbox.el"
          :label-visible="true"
          :disabled="isDisabled && isAnyChildSelected(consent)"
          :show-validation-message-outside-input="true"
          :data-test="isConsentRequired(consent) ? 'customer-consent-required' : 'customer-consent'"
          @change="onConsentChange(consent)"
        >
          <ConsentDescription.reuse
            v-if="consent.description"
            :consent="consent"
          />
        </UiFormFieldCheckbox>

        <div
          v-if="consent && consent?.children?.length"
          class="relative z-10 ml-12 mt-4 flex w-full flex-col gap-3"
        >
          <template
            v-for="childConsent of consent.children"
            :key="childConsent.code"
          >
            <UiFormFieldCheckbox
              v-if="childConsent
                && childConsent.consentId
                && childConsent.description
              "
              :id="generateUniqueId(childConsent.consentId)"
              :error-message-class="styles.checkbox.errorMessage"
              :name="childConsent.consentId"
              type="checkbox"
              :rules="isChildConsentRequired({ consent, childConsent }) ? 'required' : ''"
              container-tag="div"
              :model-value="isConsentActive(childConsent)"
              :variant="props.checkboxVariant"
              :size="props.checkboxSize"
              :label-visible="true"
              :disabled="isDisabled"
              :class="styles.checkbox.el"
              @change="onConsentChildrenChange(childConsent)"
            >
              <ConsentDescription.reuse
                v-if="childConsent.description"
                :consent="childConsent"
                :required="isChildConsentRequired({ consent, childConsent })"
              />
            </UiFormFieldCheckbox>
          </template>
        </div>
      </div>

      <UiButton
        v-if="props.editToggle && isConsentEditable(consent?.consentId)"
        variant="secondary"
        class="mt-8 self-end"
        :disabled="isDisabled"
        @click.prevent="setConsents()"
      >
        {{ t("save_changes") }}
      </UiButton>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from 'vee-validate'
import type { SizeVariantType } from '@ui/components/UiForm/UiCheckbox/UiCheckbox.types'
import UiButton from '@ui/components/UiButton/UiButton.vue'
import type {
  ConsentsArea,
  RawlplugConsent,
} from '@ecom/stores/storeConfig.types'
import { createReusableTemplate } from '@vueuse/core'
import { useCustomer } from '@customer/composables/useCustomer'
import { useCustomerQueries } from '@customer/composables/useCustomerQueries/useCustomerQueries'
import { useConsents } from '../../composables/useConsents'
import { computed } from '#imports'
import type { RawlplugConsentMetadataInput } from '#gql'

interface ConsentsInterface {
  area: ConsentsArea
  isLoading?: boolean
  checkboxSize?: SizeVariantType
  checkboxVariant?: 'primary' | 'secondary'
  marketingEmailOnly?: boolean // special case for newsletter consent
  editToggle?: boolean // shows edit button which enables editing consents (eg. in profile page)
  selectActiveConsents?: boolean // selects active consents (eg. in profile page)
  containerClass?: string[] | []
  allowSelectAll?: boolean
}

const props = withDefaults(defineProps<ConsentsInterface>(), {
  isLoading: false,
  checkboxSize: 'big',
  checkboxVariant: 'secondary',
  marketingEmailOnly: false,
  editToggle: false,
  selectActiveConsents: false,
  containerClass: () => [],
  allowSelectAll: false,
})

const emit = defineEmits<{
  (e: 'consent-changed'): void
}>()

const ConsentDescription = createReusableTemplate<{
  consent: RawlplugConsent
  required?: boolean
}>()

const { t } = useI18n()
const isDisabled = computed(
  () =>
    (props.editToggle === true && isInEditMode.value === false)
    || props.isLoading
    || internalIsLoading.value,
)

const isSelectAllSelected = ref(false)
const isInEditMode = ref<boolean | number>(false)
const internalIsLoading = ref(false)
const { customerData, refreshSectionData, isLoggedIn } = useCustomer()
const customerEmail = computed(() => customerData.value?.customer?.email ?? '')
const activeConsents = computed(() => {
  return Object.values(customerData.value?.['consent-data'] ?? []).filter(
    el => typeof el === 'object',
  )
})

const {
  controlledValues,
  validate: validateForm,
  resetForm,
  setValues,
} = useForm()
const { getConsents } = useConsents()
const consents: RawlplugConsent[] = JSON.parse(
  JSON.stringify(getConsents(props.area)),
) // deep copy to avoid reactivity (because of special case below)

/**
 * This special case is to handle email only newsletter consent
 */
if (props.marketingEmailOnly === true && props.area === 'marketing') {
  consents?.map((consent) => {
    const emailConsent = consent?.children?.find((el: RawlplugConsent) =>
      el?.code?.includes('email'),
    ) as RawlplugConsent & { lang: string }
    if (emailConsent) {
      emailConsent.description = `${consent?.description ?? ''} ${emailConsent.description
        }`
      emailConsent.moreDescription = consent?.moreDescription ?? ''
      emailConsent.isRequired = consent?.isRequired

      emailConsent.lang = consent?.lang ?? ''
      consents[0] = emailConsent
    }
    return consent
  })
}

/**
 * Transform Rawlplug Consent schema to Magento mutation
 * @param consent
 */
function transformConsentToMetadataInput(
  consent: RawlplugConsent,
): RawlplugConsentMetadataInput {
  return {
    consentId: consent?.consentId,
    versionId: consent?.versionId,
    parentId: consent?.parentId,
  }
}

/**
 * Send consents to the API endpoint
 */
async function setConsents(
  { email }: { email: string } = {
    email: customerEmail.value,
  },
) {
  const valid = await validate()
  if (!valid) {
    return
  }
  let consentsToSet: RawlplugConsentMetadataInput[] = [] // not all consents need to be sent

  // take only selected consents with true value
  const selectedConsents = Object.keys(controlledValues.value).filter(
    key => controlledValues.value[key] === true,
  )

  consents.forEach((consent) => {
    const selectedChildren
      = consent?.children?.filter(child =>
        selectedConsents.includes(child?.consentId as string),
      ) || []

    if (selectedChildren.length > 0) {
      consentsToSet = consentsToSet.concat(
        selectedChildren.map(child => transformConsentToMetadataInput(child)),
      )
    }
    if (selectedConsents.includes(consent?.consentId as string)) {
      consentsToSet.push(transformConsentToMetadataInput(consent))
    }
  })

  return await saveConsents(consentsToSet, email)
}

/**
 * Receives all consents to save, prepares default values and makes mutation
 * @param consentsToSet
 * @param email
 */
async function saveConsents(
  consentsToSet: RawlplugConsentMetadataInput[],
  email = customerEmail.value,
) {
  /**
   * Every consent has the same token and lang, so we can get it from the first one
   */
  const token = consents?.[0]?.token
  const lang = consents?.[0]?.lang

  if (token && lang && email) {
    internalIsLoading.value = true
    try {
      const { rawlplugSetConsents } = useCustomerQueries()
      return await rawlplugSetConsents({
        email,
        token,
        lang,
        userMetadata: consentsToSet,
      })
    }
    finally {
      if (isLoggedIn.value) {
        await refreshSectionData({ lazy: true })
      }
      isInEditMode.value = false
      internalIsLoading.value = false
    }
  }
  else {
    isInEditMode.value = false
    return Promise.reject(
      new Error(`Missing required data (email, token, lang or consents)`),
    )
  }
}

function switchEditMode(consentId: string | null = '', cancel = false) {
  const consent = Number(consentId)
  if (cancel) {
    resetForm()
  }

  isInEditMode.value = isInEditMode.value === consent ? false : consent
}

function isConsentEditable(consentId: string | null = '') {
  if (!props.editToggle) {
    return true
  }
  if (isInEditMode.value === true) {
    return true
  }
  return isInEditMode.value === Number(consentId)
}

/**
 * Set additional validation rule for subConsentMinimum
 * @param consent
 */
function isConsentRequired(consent: RawlplugConsent) {
  return Boolean(consent?.isRequired || consent?.subConsentMinimum)
}

/**
 * required children consents need to be required only when parent consent is required and selected
 */
function isChildConsentRequired({ consent, childConsent }: { consent: RawlplugConsent, childConsent: RawlplugConsent }): boolean {
  return controlledValues.value?.[consent?.consentId ?? '']
    ? isConsentRequired(childConsent)
    : isConsentRequired(consent) && isConsentRequired(childConsent)
}

/**
 * Toggle children active states based on parent selection
 * @param consent
 */
async function onConsentChange(consent: RawlplugConsent) {
  const isChecked = controlledValues.value[consent?.consentId ?? -1] === true
  const anySelected = isAnyChildSelected(consent)

  if (consent?.subConsentMinimum && consent?.children) {
    if ((isChecked && !anySelected) || !isChecked) {
      const children = prepareAllChildrenForSelection(consent, isChecked)
      setValues(children)
    }
  }

  // uncheck all children if parent is unchecked
  if (!isChecked && consent?.children) {
    consent.children.forEach((child) => {
      setValues({
        [child?.consentId ?? -1]: false,
      })
    })
  }

  await validate()
  emit('consent-changed')
}

/**
 * Toggle active state on parent based on children selecion
 * @param consent
 */
async function onConsentChildrenChange(consent: RawlplugConsent) {
  const isParentChecked
    = controlledValues.value[consent?.parentId ?? -1] === true

  const parentConsent = consents.find(
    el => el?.consentId === consent?.parentId,
  )

  // if parent is checked and one of children is unchecked, uncheck parent
  // if parent is unchecked and one of children is checked, check parent
  if (parentConsent && consent?.parentId) {
    const anySelected = isAnyChildSelected(parentConsent)
    if (anySelected && !isParentChecked) {
      setValues({
        [consent.parentId]: true,
      })
    }
    if (!anySelected && isParentChecked) {
      setValues({
        [consent.parentId]: false,
      })
    }
  }

  await validate()
  emit('consent-changed')
}

/**
 * Transform all children to object required for setValues
 * eg. {"123": true}
 * @param consent
 * @param value
 */
function prepareAllChildrenForSelection(
  consent: RawlplugConsent,
  value: boolean,
) {
  if (!consent?.children?.length) {
    return {}
  }

  const children: {
    [key: string]: boolean
  } = consent?.children?.reduce(
    (accumulator: { [key: string]: boolean }, current: RawlplugConsent) => {
      if (current && current.consentId) {
        accumulator[current.consentId] = value
        return accumulator
      }
      return { noid: false }
    },
    {},
  )

  return children
}

async function validate() {
  const result = await validateForm()
  return result.valid
}

function isConsentActive(consent: RawlplugConsent): boolean {
  if (props.selectActiveConsents === true && consent?.consentId) {
    return !!activeConsents.value.find((activeConsent) => {
      if (typeof activeConsent !== 'number') {
        return activeConsent.consent_id === Number(consent.consentId)
      }
      return false
    })
  }
  return false
}

function isAnyChildSelected(consent: RawlplugConsent) {
  if (consent?.children?.length) {
    return consent.children.some((child) => {
      const childId = child?.consentId
      if (!childId) {
        return false
      }
      return controlledValues.value[childId] === true
    })
  }
  return false
}

function generateUniqueId(consentId: string | number) {
  // useState to prevent hydration mismatches
  const componentUid = useState(`consentComponentUid-${consentId}`, () =>
    Math.round(Math.random() * Date.now()))
  return `${consentId}-${componentUid.value}`
}

function toggleAllConsents() {
  isSelectAllSelected.value = !isSelectAllSelected.value
  Object.keys(controlledValues.value).forEach((key) => {
    setValues({ [key]: isSelectAllSelected.value })
  })
}

/**
 * details & summary elements are inside label
 * this is workaround to make it work with UiCheckbox (on label click select checkbox)
 * @param event
 */
function toggleFix(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (target?.closest('[data-el="consent-label"')) {
    event.preventDefault()
    target.closest('label')?.click()
  }
}

defineExpose({ consents, setConsents, validate })

const styles = {
  checkbox: {
    el: '!mr-2.5',
    errorMessage: '!text-sm !mt-2 !pt-0 !pl-0',
  },
}

watch(controlledValues, () => {
  const allValuesSelected = Object.values(controlledValues.value).every(
    item => item === true,
  )
  const allValuesNotSelected = Object.values(controlledValues.value).every(
    item => item === false,
  )

  if (allValuesSelected) {
    isSelectAllSelected.value = true
    return
  }

  if (allValuesNotSelected) {
    isSelectAllSelected.value = false
  }
})

const isSelectAllCheckboxIndeterminate = computed(() => {
  const valuesArray = Object.values(controlledValues.value)

  return valuesArray.includes(true) && valuesArray.includes(false)
})
</script>

<style lang="postcss">
[data-el='customer-consents'] details summary::-webkit-details-marker {
  @apply hidden;
}

[data-el='customer-consent'] {
  .customer-consent-name p {
    font-size: inherit;
  }

  label[data-el='ui-checkbox'] + p {
    @apply ml-[3.1rem] -mt-1.5;
  }
}

.agreement-content {
  @apply w-full leading-5 text-inherit;

  &--required {
    p {
      @apply after:ml-0.5 after:text-red-500 after:leading-none !my-0;

      &::after {
        content: '*';
      }
    }
  }

  &--email-only {
    p {
      @apply inline;

      &:first-child::after {
        @apply hidden;
      }
    }
  }

  p {
    @apply leading-5 text-sm text-inherit;
  }

  a {
    @apply underline;
  }
}
</style>
