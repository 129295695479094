import { storeToRefs } from 'pinia'
import { useStoreConfigStore } from '@ecom/stores/storeConfig'

function useConsents() {
  const storeConfigStore = useStoreConfigStore()

  const { consents } = storeToRefs(storeConfigStore)
  const { getConsents } = storeConfigStore

  return {
    consents,
    getConsents,
  }
}

export { useConsents }
